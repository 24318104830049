import React, { useEffect, useState } from "react";
import * as Cesium from "cesium";

type HeightAwareEntityProps = {
  position: Cesium.Cartesian3;
  coordinates: number[];
  heightFunction: (position: Cesium.Cartesian3) => Promise<number | undefined>;
  offset?: number;
  children({ position }: { position: Cesium.Cartesian3 }): React.ReactNode;
};

export const HeightAwareEntity: React.FC<HeightAwareEntityProps> = ({
  position,
  heightFunction,
  coordinates,
  offset = 0,
  children,
}) => {
  const [heightAwarePosition, setHeightAwarePosition] =
    useState<Cesium.Cartesian3>(position);
  useEffect(() => {
    const calculateHeight = async () => {
      const height = await heightFunction(position);
      if (!height) return;
      setHeightAwarePosition((pos) => {
        return Cesium.Cartesian3.fromDegrees(
          coordinates[0],
          coordinates[1],
          height + offset,
        );
      });
    };
    calculateHeight();
  }, [position, offset]);
  if (!children) return null;
  return children({ position: heightAwarePosition });
};
