import React, { useMemo } from "react";
import { Entity, PolygonGraphics } from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";
import { Features, Process } from "../../../types/media";

interface DatasetGeoJsonComponent {
  isPylonSearch: boolean;
  selectedProcesses: Process[];
  hoverProcessId: string | null;
  setClickedProcess: (process: Process | null) => void;
  is3D: boolean;
  getPointCloudData: (id: string | undefined, type: string) => any[];
}

export const DatasetGeoJsonComponent = ({
  isPylonSearch,
  selectedProcesses,
  hoverProcessId,
  setClickedProcess,
  is3D,
  getPointCloudData,
}: DatasetGeoJsonComponent) => {
  const hoveredProcess = useMemo(() => {
    if (isPylonSearch && !hoverProcessId) return null;
    const process = selectedProcesses.find(
      (item) => item.id === hoverProcessId,
    );
    if (!process || !process?.geoJson) return null;
    return (
      <ProcessGraphics
        key={process.id}
        process={process}
        is3D={is3D}
        getPointCloudData={getPointCloudData}
      />
    );
  }, [hoverProcessId, selectedProcesses, is3D, getPointCloudData]);

  const processes = useMemo(() => {
    return selectedProcesses.map((process) => {
      return (
        <ProcessGraphics
          process={process}
          key={process.id}
          is3D={is3D}
          getPointCloudData={getPointCloudData}
          onClick={() => {
            setClickedProcess(process);
          }}
        />
      );
    });
  }, [selectedProcesses, is3D, getPointCloudData]);
  return isPylonSearch ? hoveredProcess : processes;
};

export const ProcessGraphics: React.FunctionComponent<
  {
    process: Process;
    onClick?: () => void;
  } & Pick<DatasetGeoJsonComponent, "getPointCloudData" | "is3D">
> = ({ process, getPointCloudData, is3D, onClick = () => {} }) => {
  const height = useMemo(() => {
    if (!is3D) return 0;
    const pointCloudDetails = getPointCloudData(process.id, "process");
    if (pointCloudDetails.length > 0) {
      let maxHeight = 0;
      pointCloudDetails.forEach((item) => {
        maxHeight = Math.max(pointCloudDetails[0].pcMaxHeight, maxHeight);
      });
      return maxHeight;
    }
    return 1;
  }, [process, is3D, getPointCloudData]);

  const geoJson: Features = process?.geoJson as unknown as Features;
  if (
    !geoJson ||
    !Array.isArray(geoJson.features) ||
    !geoJson.features[0].geometry
  )
    return null;
  const processCoords = Cesium.Cartesian3.fromDegreesArray(
    (geoJson.features[0].geometry.coordinates[0] as number[]).flat(),
  );
  return (
    <Entity
      id={process.id}
      name={process.name}
      onClick={onClick}
      properties={{ type: is3D ? "process_3d" : "process_2d" }}
    >
      <PolygonGraphics
        hierarchy={processCoords}
        {...EntityGraphicsState.NORMAL_process_Polygon_graphics}
        heightReference={Cesium.HeightReference.RELATIVE_TO_TERRAIN}
        height={is3D ? 0 : 2}
        extrudedHeight={height}
      />
    </Entity>
  );
};
