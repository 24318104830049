import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  EllipsoidGraphics,
  Entity,
  PointGraphics,
  PolygonGraphics,
  useCesium,
} from "resium";
import * as Cesium from "cesium";
import { EntityGraphicsState } from "../MDPViewer";
import {
  ApiMetadataResponse,
  Features,
  Metadata,
  Process,
  MediaDataType,
  DroneImageDetail,
} from "../../../types/media";
import { useApi } from "../../../hooks/useApi";
import { ImageCache } from "../Components/ResultsWindow";
import { createMetadataFrustum, parse3DPos } from "../../../util/coordinates";
import { useStyles } from "../styles";

interface CesiumGeoJsonCompProp {
  mapData: ApiMetadataResponse;
  is3D?: boolean;
  isPylonSearch?: boolean;
  clickedProcess?: Process | null;
  hoverId: string | null;
  selectedImageDetails: DroneImageDetail | null;
  getPointCloudData: (id: string | undefined, type?: string) => any[];
  heightFunction: (position: Cesium.Cartesian3) => Promise<number | undefined>;
}

const loadingStateImage: string[] = [];

export const MetadataGeoJsonComponent = ({
  mapData,
  isPylonSearch = false,
  is3D = false,
  clickedProcess = null,
  getPointCloudData,
  selectedImageDetails,
  hoverId,
  heightFunction,
}: CesiumGeoJsonCompProp) => {
  const { classes } = useStyles();
  const [, setRefresh] = useState<number>(0);
  const { getImage } = useApi();
  const [data, setData] = useState<Metadata[]>([]);
  const { viewer } = useCesium();
  const frustumPrimitive = useRef<Cesium.Primitive | undefined>(undefined);

  useEffect(() => {
    if (mapData.results) {
      setData(mapData.results);
    } else {
      setData([]);
    }
  }, [mapData]);

  useEffect(() => {
    if (!viewer) return;
    if (!hoverId && frustumPrimitive.current) {
      viewer.scene.primitives.remove(frustumPrimitive.current);
      viewer.entities.removeById("frustum_image");
      frustumPrimitive.current = undefined;
    }
    if (!is3D || !selectedImageDetails) return;
    const hoverMetadata = data.find((item) => item.id === hoverId);
    if (hoverMetadata) {
      (async () => {
        const metadataId = `${hoverMetadata.id}`;
        const position3D = parse3DPos(hoverMetadata.geom3D);
        const terrainHeight = (await heightFunction(position3D)) || 0;
        const frustumEntity = createMetadataFrustum(
          viewer,
          hoverMetadata,
          selectedImageDetails,
          terrainHeight,
          frustumPrimitive,
        );

        // image for the frustum
        const image = new Image();
        // cache
        if (metadataId in ImageCache && frustumEntity.box) {
          image.src = URL.createObjectURL(ImageCache[metadataId]);
          frustumEntity.box.material = new Cesium.ImageMaterialProperty({
            image: image, // Replace with the actual URL of your image
            transparent: false, // Optional: Make the material transparent if the image has transparency
          });
        } else {
          if (!loadingStateImage.includes(metadataId)) {
            loadingStateImage.push(metadataId);
            getImage(metadataId).then((resp) => {
              ImageCache[metadataId] = resp.data;
              image.src = URL.createObjectURL(ImageCache[metadataId]);
              image.onload = () => {
                // for image refresh
                setRefresh(new Date().getTime());
              };
              const entity = viewer.entities.getById("frustum_image");
              if (
                entity &&
                entity.box &&
                entity.properties?.metadataId.getValue() === metadataId
              ) {
                entity.box.material = new Cesium.ImageMaterialProperty({
                  image: image, // Replace with the actual URL of your image
                  transparent: false, // Optional: Make the material transparent if the image has transparency
                });
              }
            });
          }
        }
      })();
    }

    return () => {
      if (!viewer) return;
      if (frustumPrimitive.current) {
        viewer.scene.primitives.remove(frustumPrimitive.current);
        frustumPrimitive.current = undefined;
      }
      viewer.entities.removeById("frustum_image");
    };
  }, [data, viewer, hoverId, is3D, selectedImageDetails]);

  const metadata = useMemo(() => {
    const filterFn = clickedProcess
      ? (item) => item.processId === clickedProcess.id
      : () => true;
    return data.filter(filterFn).map((marker) => {
      const geoJson: Features = marker?.geoJson as unknown as Features;
      if (!geoJson.features[0].geometry) return null;
      const metadata = marker as Metadata;

      if (metadata.mediadataType === MediaDataType.DroneImage) {
        const position = parse3DPos(marker.geom3D);
        return (
          <Entity
            key={metadata.id}
            id={metadata.id}
            position={position}
            name={metadata.fileName}
            properties={{ type: is3D ? "metadata_3d" : "metadata_2d" }}
          >
            {is3D ? (
              <EllipsoidGraphics
                {...EntityGraphicsState.NORMAL_metadata_Ellipsoid_Graphics}
                heightReference={Cesium.HeightReference.RELATIVE_TO_TERRAIN}
              />
            ) : (
              <PointGraphics
                {...EntityGraphicsState.NORMAL_metadata_Point_Graphics}
                heightReference={Cesium.HeightReference.NONE}
                disableDepthTestDistance={Number.POSITIVE_INFINITY}
              />
            )}
          </Entity>
        );
      } else if (metadata.mediadataType === MediaDataType.PointCloud) {
        const metadataRectCoord = Cesium.Cartesian3.fromDegreesArray(
          (geoJson.features[0].geometry.coordinates[0] as number[]).flat(),
        );
        const pointCloudDetails = getPointCloudData(metadata.id);
        let maxHeight = 0;
        if (pointCloudDetails.length > 0) {
          maxHeight = pointCloudDetails[0].pcMaxHeight;
        }
        const graphics = is3D
          ? EntityGraphicsState.NORMAL_metadata_Polygon_graphics
          : EntityGraphicsState.NORMAL_metadata_Polygon_graphics;

        return (
          <Entity
            key={metadata.id}
            id={metadata?.id}
            selected={false}
            properties={{
              type: is3D
                ? "metadata_point_cloud_3d"
                : "metadata_point_cloud_2d",
            }}
          >
            <PolygonGraphics
              hierarchy={metadataRectCoord}
              {...graphics}
              heightReference={Cesium.HeightReference.CLAMP_TO_TERRAIN}
              extrudedHeight={is3D ? maxHeight : 2}
            />
          </Entity>
        );
      }
    });
  }, [data, is3D, clickedProcess, getPointCloudData, heightFunction]);

  // do not render if there is no data
  const length = data?.length;
  if (length != undefined && length <= 0) return null;
  if (isPylonSearch && clickedProcess === null) return null;
  const hoverMetadata = data.find((item) => item.id === hoverId);
  return (
    <>
      {metadata}
      {is3D && hoverMetadata && (
        <img
          src={
            `${hoverMetadata?.id}` in ImageCache
              ? URL.createObjectURL(ImageCache[`${hoverMetadata.id}`])
              : "loading.png"
          }
          alt={hoverMetadata.id}
          className={classes.metadataImagePreview}
        />
      )}
    </>
  );
};
