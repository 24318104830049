import * as Cesium from "cesium";
import { DroneImageDetail, Metadata } from "../types/media";

export const calculateLangLatBoundingBox = (latLongArray: number[][]) => {
  if (latLongArray.length === 0) {
    return null; // No points to create a bounding box
  }

  // Initialize min and max values with the first point
  let minLat = latLongArray[0][1];
  let maxLat = latLongArray[0][1];
  let minLng = latLongArray[0][0];
  let maxLng = latLongArray[0][0];

  // Iterate through the array to find min and max values
  for (let i = 1; i < latLongArray.length; i++) {
    const lat = latLongArray[i][1];
    const lng = latLongArray[i][0];

    // Update min and max values for latitude
    if (lat < minLat) minLat = lat;
    if (lat > maxLat) maxLat = lat;

    // Update min and max values for longitude
    if (lng < minLng) minLng = lng;
    if (lng > maxLng) maxLng = lng;
  }

  // Create and return the bounding box
  const boundingBox = {
    maxLng: maxLng, // east
    minLng: minLng, // west
    maxLat: maxLat, // north
    minLat: minLat, // south
  };

  return boundingBox;
};

export const calculateBoundingBox = (entities: Cesium.Entity[]) => {
  const langLatArray: number[][] = [];

  entities.forEach((entity: Cesium.Entity) => {
    const type = Cesium.defined(entity?.properties?.type)
      ? entity?.properties?.type.getValue()
      : "";

    // console.log(type);
    if (
      [
        "pylon_2d",
        "process_2d",
        "metadata_2d",
        "metadata_point_cloud_2d",
      ].includes(type)
    ) {
      // point cartographic
      const position = entity.position?.getValue(
        new Cesium.JulianDate(),
      ) as Cesium.Cartesian3;

      // point
      if (position) {
        const cartographic = Cesium.Cartographic.fromCartesian(
          position,
          Cesium.Ellipsoid.WGS84,
        );

        langLatArray.push([
          Cesium.Math.toDegrees(cartographic.longitude),
          Cesium.Math.toDegrees(cartographic.latitude),
        ]);
      }
      // polygon
      else {
        const positions = entity?.polygon?.hierarchy?.getValue(
          new Cesium.JulianDate(),
        ).positions;
        if (positions) {
          positions.forEach((cartesian: Cesium.Cartesian3) => {
            // point cartographic
            const cartographic = Cesium.Cartographic.fromCartesian(
              cartesian,
              Cesium.Ellipsoid.WGS84,
            );

            langLatArray.push([
              Cesium.Math.toDegrees(cartographic.longitude),
              Cesium.Math.toDegrees(cartographic.latitude),
            ]);
          });
        }
      }
    }
  });

  return calculateLangLatBoundingBox(langLatArray);
};

export const parse3DPosFromPolygonRaw = (location: string = "") => {
  const coordinates: string[] | null = location.match(/\(\(([^)]+)\)/);
  if (!(Array.isArray(coordinates) && coordinates.length > 1)) return [];
  const coordArray: number[] = coordinates[1]
    .split(" ")
    .map((item) => parseFloat(item));
  const chunks: number[][] = [];
  const chunkSize = 3; // long, lat, alt
  for (let i = 0; i < coordArray.length; i += chunkSize) {
    chunks.push(coordArray.slice(i, i + chunkSize));
  }
  return chunks;
};

export const parse3DPosRaw = (location: string = "") => {
  const coordinates: string[] | null = location.match(/\(([^)]+)\)/);
  if (!(Array.isArray(coordinates) && coordinates.length > 1)) return [];
  const coordArray: number[] = coordinates[1]
    .split(" ")
    .map((item) => parseFloat(item));
  coordArray[2] -= 100; // TEMP FIX
  return coordArray;
};

export const parse3DPos = (location: string = "") => {
  const coordinates: string[] | null = location.match(/\(([^)]+)\)/);
  if (!(Array.isArray(coordinates) && coordinates.length > 1))
    return new Cesium.Cartesian3();
  const coordArray: number[] = coordinates[1]
    .split(" ")
    .map((item) => parseFloat(item));
  coordArray[2] -= 100; // TEMP FIX
  return Cesium.Cartesian3.fromDegrees(
    coordArray[0],
    coordArray[1],
    coordArray[2],
  );
};

export const createMetadataFrustum = (
  viewer: Cesium.Viewer,
  metadata: Metadata,
  selectedImageDetails: DroneImageDetail,
  terrainHeight: number,
  frustumPrimitive: React.MutableRefObject<Cesium.Primitive | undefined>,
): Cesium.Entity => {
  // clean up
  viewer.scene.primitives.remove(frustumPrimitive.current);
  viewer.entities.removeById("frustum_image");

  // FRUSTUM
  const metadataId = `${metadata.id}`;
  const positionRaw = parse3DPosRaw(metadata.geom3D);

  viewer.scene.primitives.remove(frustumPrimitive.current);
  viewer.entities.removeById("frustum_image");

  // FRUSTUM
  const offset = 1; // ellipsis radius
  const dronePosition = Cesium.Cartesian3.fromDegrees(
    positionRaw[0],
    positionRaw[1],
    positionRaw[2] + terrainHeight + offset,
  );

  const heading = Cesium.Math.toRadians(
    parseFloat(selectedImageDetails.flightinfoGimbalYawDegree),
  );
  const pitch = Cesium.Math.toRadians(
    parseFloat(selectedImageDetails.flightinfoGimbalPitchDegree),
  );
  const roll = Cesium.Math.toRadians(
    parseFloat(selectedImageDetails.flightinfoGimbalRollDegree),
  );

  const camera = new Cesium.Camera(viewer.scene);
  camera.frustum = new Cesium.PerspectiveFrustum({
    fov: Cesium.Math.toRadians(65.7),
    near: 0.1,
    far: 10.0,
    aspectRatio: 1,
  });

  const orientationValues = {
    heading: heading, // Camera heading angle
    pitch: pitch, // Camera pitch (looking down)
    roll: roll, // No roll
  };

  camera.setView({
    destination: dronePosition,
    orientation: orientationValues,
  });

  // const position = camera.positionWC;
  const direction = camera.directionWC;
  const up = camera.upWC;
  let right = camera.rightWC;
  const scratchRight = new Cesium.Cartesian3();
  right = Cesium.Cartesian3.negate(right, scratchRight);

  const rotation = new Cesium.Matrix3();
  Cesium.Matrix3.setColumn(rotation, 0, right, rotation);
  Cesium.Matrix3.setColumn(rotation, 1, up, rotation);
  Cesium.Matrix3.setColumn(rotation, 2, direction, rotation);

  const orientation = Cesium.Quaternion.fromRotationMatrix(rotation);

  const frustum = camera.frustum.clone() as Cesium.PerspectiveFrustum;

  frustumPrimitive.current = viewer.scene.primitives.add(
    new Cesium.Primitive({
      geometryInstances: new Cesium.GeometryInstance({
        geometry: new Cesium.FrustumOutlineGeometry({
          frustum,
          origin: camera.position,
          orientation: orientation,
        }),
        // releaseGeometryInstances:false,
        attributes: {
          // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          color: Cesium.ColorGeometryInstanceAttribute.fromColor(
            Cesium.Color.RED,
          ),
        },
        id: "drone_perspective",
      }),
      appearance: new Cesium.PerInstanceColorAppearance({
        translucent: false,
        flat: true,
        closed: true,
      }),
      asynchronous: false,
    }),
  );

  // IMAGE
  // Frustum dimensions on the far plane
  const farHeight =
    frustum.fov !== undefined
      ? frustum.far *
        Math.tan(frustum.fov / 2) *
        ((frustum.aspectRatio ?? 1) * 2)
      : 0; // Default to 0 or handle the case where fov is undefined
  const farWidth = farHeight * (frustum.aspectRatio ?? 1) * 1;

  // Calculate the 4 far corners of the frustum in local space (camera space)
  const farTopLeft = new Cesium.Cartesian3(
    -farWidth / 2,
    farHeight / 2,
    frustum.far,
  );
  const farTopRight = new Cesium.Cartesian3(
    farWidth / 2,
    farHeight / 2,
    frustum.far,
  );
  const farBottomLeft = new Cesium.Cartesian3(
    -farWidth / 2,
    -farHeight / 2,
    frustum.far,
  );
  const farBottomRight = new Cesium.Cartesian3(
    farWidth / 2,
    -farHeight / 2,
    frustum.far,
  );

  // Transform far corners from local space to world space using the orientation and position of the frustum
  const farTopLeftWorld = Cesium.Matrix4.multiplyByPoint(
    Cesium.Matrix4.fromRotationTranslation(
      Cesium.Matrix3.fromQuaternion(orientation),
      dronePosition,
    ),
    farTopLeft,
    new Cesium.Cartesian3(),
  );

  const farTopRightWorld = Cesium.Matrix4.multiplyByPoint(
    Cesium.Matrix4.fromRotationTranslation(
      Cesium.Matrix3.fromQuaternion(orientation),
      dronePosition,
    ),
    farTopRight,
    new Cesium.Cartesian3(),
  );

  const farBottomLeftWorld = Cesium.Matrix4.multiplyByPoint(
    Cesium.Matrix4.fromRotationTranslation(
      Cesium.Matrix3.fromQuaternion(orientation),
      dronePosition,
    ),
    farBottomLeft,
    new Cesium.Cartesian3(),
  );

  const farBottomRightWorld = Cesium.Matrix4.multiplyByPoint(
    Cesium.Matrix4.fromRotationTranslation(
      Cesium.Matrix3.fromQuaternion(orientation),
      dronePosition,
    ),
    farBottomRight,
    new Cesium.Cartesian3(),
  );

  // Compute the center of the box (midpoint of the 4 points)
  const center = Cesium.Cartesian3.midpoint(
    farTopLeftWorld,
    farBottomRightWorld,
    new Cesium.Cartesian3(),
  );

  // Compute the dimensions of the box
  const length = Cesium.Cartesian3.distance(farTopLeftWorld, farTopRightWorld); // length in X direction
  const width = Cesium.Cartesian3.distance(farTopLeftWorld, farBottomLeftWorld); // width in Y direction
  const height = 0.1; // Height in the Z direction (fixed)

  // Add the box to the scene
  const frustumEntity = viewer.entities.add({
    id: "frustum_image",
    position: center,
    properties: {
      metadataId: metadataId,
    },
    box: {
      dimensions: new Cesium.Cartesian3(length, width, height), // Set the box dimensions
      // material: Cesium.Color.GREEN.withAlpha(0.5),  // Semi-transparent blue material
      material: new Cesium.ImageMaterialProperty({
        image: "loading.png", // Replace with the actual URL of your image
        transparent: false, // Optional: Make the material transparent if the image has transparency
      }),
    },
    orientation: orientation, // Apply the orientation to the box
  });

  return frustumEntity;
};
